"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Marquee = void 0;
var Marquee = /** @class */ (function () {
    function Marquee() {
        this.inner = document.querySelector('.mp_alertbanner-inner');
        this.animationLoop();
    }
    Marquee.prototype.animationLoop = function () {
        var _this = this;
        if (this.inner == null)
            return;
        this.inner.animate({ left: '-150%' }, { duration: 40000, easing: 'linear' }).onfinish = function () { return _this.animationLoop(); };
    };
    return Marquee;
}());
exports.Marquee = Marquee;
